import notification from '@ctm/ui/src/components/Notification/notification';

function getBearerToken() {
    let oath = localStorage.getItem('oauth');
    if (!oath) {
        window.location.href = '/#/login';
    } else {
        return JSON.parse(oath).access_token;
    }
}

function showError() {
    const key = notification.generateKey();

    notification.open({
        key,
        isClosable: true,
        title: 'Error encountered',
        placement: 'topRight',
        actions: {
            Ok: () => {
                notification.cancel(key);
            },
        },
    });
}

const APIs = {
    searchHotels: { url: `${endPointUrl}/api/v2/Hotels/SearchHotels`, method: 'POST' },
    bulkUpdate: { url: `${endPointUrl}/api/v2/Hotels/BulkUpdateHotelRankingBySearchCriteria`, method: 'PUT' },
    saveHotelRankings: { url: `${endPointUrl}/api/v2/Hotels/BulkUpdateHotelRanking`, method: 'PUT' },
    saveDebtorHotels: { url: `${endPointUrl}/api/v2/DebtorHotels/SaveDebtorHotels`, method: 'POST' },
};

export async function apiCall(api, body) {
    try {
        const { url, method } = APIs[api];

        let response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getBearerToken(),
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw Error(response.statusText);
        }

        const json = await response.json();
        return json;
    } catch (error) {
        showError();
        throw error;
    }
}

export async function searchDebtors(debtorName) {
    try {
        const response = await fetch(
            `${endPointUrl}/odata/Debtors?$select=Name&$filter=contains(tolower(Name), '${debtorName.toLowerCase()}') and IsDeleted ne true`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            }
        );

        if (!response.ok) {
            throw Error(response.statusText);
        }

        const json = await response.json();
        return json;
    } catch (error) {
        showError();
        throw error;
    }
}

export async function searchDebtorHotels(DebtorIdentifier, HotelIds) {
    try {
        let body = {
            DebtorIdentifier,
            ReturnHotelInfo: true,
            IsDeleted: false,
        };
        if (HotelIds) body.HotelIds = HotelIds;

        const response = await fetch(`${endPointUrl}/api/v2/DebtorHotels/SearchDebtorHotels`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getBearerToken()}`,
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            showError();
            throw Error(response.statusText);
        }

        const json = await response.json();
        return json;
    } catch (error) {
        showError();
        throw error;
    }
}
