<script>
    import countries from '../../src/static/countries.json';
    import Select from 'svelte-select';

    import { selectedCountry } from './hotel-rankings-store';

    function handleSelect(e) {
        $selectedCountry = e.detail;
    }

    function handleClear() {
        $selectedCountry = null;
    }
</script>

<div class="country-search">
    <Select
        value={$selectedCountry}
        items={countries}
        optionIdentifier="code"
        labelIdentifier="name"
        on:select={handleSelect}
        on:clear={handleClear} />
</div>

<style>
    .country-search {
        flex-grow: 1;
        --height: 38px;
        --borderRadius: 0.25rem;
        --clearSelectTop: 6px;
    }
</style>
