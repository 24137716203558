<script>
    import Pagination from '@ctm/ui/src/components/Pagination/Pagination.svelte';

    import {
        hotelSearchResults,
        hotelSearchResultsPagination,
        showLoader,
        onlyShowDebtorHotels,
        showPagination,
        activeDebtorId,
        selectedDebtorId,
        activeCountry,
        selectedCountry,
        activeCity,
        selectedCity,
        activeChain,
        selectedChain,
        activeAirportCode,
        selectedAirportCode,
        showBulkUploadModal,
        selectedHotelsToRank,
        activeChainCode,
        selectedChainCode
    } from './hotel-rankings-store';

    import { apiCall, searchDebtorHotels } from './hotel-rankings-services';
    import SearchForm from './SearchForm.svelte';
    import Rankings from './Rankings.svelte';
    import SearchResults from './SearchResults.svelte';
    import BulkUpdateModal from './BulkUpdateModal.svelte';

    function handleBack() {
        history.back();
    }

    function handlePageChange(event) {
        $hotelSearchResultsPagination.current = event.detail.current;
        handleSearch();
    }

    let searchParams = {
        Deleted: false,
        Pagination: {
            Page: $hotelSearchResultsPagination.current,
            PageSize: 10,
        },
    };

    async function handleSearch(args = { updateFilters: false }) {
        $showLoader = true;

        if (args.updateFilters) {
            $selectedHotelsToRank = {};
            $activeDebtorId = $selectedDebtorId;
            $activeCountry = $selectedCountry;
            $activeCity = $selectedCity;
            $activeChain = $selectedChain;
            $activeAirportCode = $selectedAirportCode;
            $activeChainCode = $selectedChainCode;
        }

        if ($activeDebtorId && $onlyShowDebtorHotels) {
            try {
                $showPagination = false;
                const res = await searchDebtorHotels($activeDebtorId);

                $hotelSearchResults = res.map((item) => {
                    return {
                        Id: item.HotelId,
                        Name: item.Hotel.Name,
                        City: item.Hotel.City,
                        Chain: item.Hotel.Chain,
                        Country: item.Hotel.Country,
                        AirportCode: item.Hotel.AirportCode,
                        Ranking: item.Hotel.Ranking,
                        DebtorRanking: item.Ranking,
                        DebtorHotelId: item.id,
                        DebtorIdentifier: item.DebtorIdentifier,
                    };
                }).sort((a, b) => a.Name.localeCompare(b.Name));

                $hotelSearchResults = $hotelSearchResults.filter((hotel) => {
                    let keepHotel = true;

                    if ($activeCountry) {
                        keepHotel = hotel.Country === $activeCountry.code || $activeCountry.name;
                    }

                    if (keepHotel && $activeCity) {
                        keepHotel = hotel.City.toLowerCase() === $activeCity.toLowerCase();
                    }

                    if (keepHotel && $activeChain) {
                        keepHotel = hotel.Chain.toLowerCase() === $activeChain.toLowerCase();
                    }

                    if (keepHotel && $activeAirportCode) {
                        keepHotel = hotel.AirportCode.toLowerCase() === $activeAirportCode.toLowerCase();
                    }

                    return keepHotel;
                });
            } catch (error) {
                $hotelSearchResults = [];
                console.warn(error);
            }
        } else {
            $showPagination = true;

            if (args.updateFilters) {
                $hotelSearchResultsPagination.current = 1;

                searchParams = {
                    Deleted: false,
                };

                if ($activeCountry) {
                    searchParams.Country = $activeCountry.code;
                }

                if ($activeCity) {
                    searchParams.City = $activeCity.toLowerCase();
                }

                if ($activeChain) {
                    searchParams.ChainCodeName = $activeChain.toLowerCase();
                }

                if ($activeAirportCode) {
                    searchParams.AirportCode = $activeAirportCode.toUpperCase();
                }

                if ($activeChainCode) {
                    searchParams.SabreChainCode = $activeChainCode.toUpperCase();
                }
            }

            searchParams.Pagination = {
                Page: $hotelSearchResultsPagination.current,
                PageSize: 10,
            };

            try {
                $hotelSearchResults = $hotelSearchResults.map((hotel) => {
                    if (hotel.DebtorHotelId) delete hotel.DebtorHotelId;
                    return hotel;
                });
                const res = await apiCall('searchHotels', searchParams);
                $hotelSearchResultsPagination = {
                    current: res.Result.CurrentPage,
                    pageSize: res.Result.PageSize,
                    total: res.Result.RowCount,
                    canNavigate: true,
                    showSummary: true,
                };
                $hotelSearchResults = res.Result.Results;

                if ($activeDebtorId) {
                    let hotelIds = $hotelSearchResults.map((item) => item.Id);
                    const debtorRes = await searchDebtorHotels($activeDebtorId, hotelIds);
                    debtorRes.forEach((hotel) => {
                        let index = $hotelSearchResults.findIndex((i) => i.Id === hotel.HotelId);
                        if (index !== -1) {
                            $hotelSearchResults[index].DebtorHotelId = hotel.id;
                            $hotelSearchResults[index].DebtorRanking = hotel.Ranking;
                        }
                    });
                }
            } catch (error) {
                $hotelSearchResults = [];
                console.warn(error);
            }
        }

        $showLoader = false;
    }

    function handleShowBulkModal() {
        $showBulkUploadModal = true;
    }
</script>

<svelte:head>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css" />
</svelte:head>

<div class="hotel-rankings">
    <div class="return">
        <button on:click={handleBack} class="btn btn-primary btn-sm"
            ><i class="bi bi-arrow-left-circle-fill" />

            Return to CTM Config</button>
    </div>
    <h1 class="">Hotel Rankings</h1>
    <p class="lead">Manage the ranking influence of the CTM Recommended Hotels on Lightning Book.</p>

    <div class="search-form">
        <SearchForm on:search={() => handleSearch({ updateFilters: true })} />
    </div>
    <div class="search-results">
        <SearchResults />

        <div class="search-results-footer">
            {#if $hotelSearchResults.length > 0}
                <button disabled={$activeDebtorId} class="btn btn-primary" on:click={handleShowBulkModal}
                    >Bulk update</button>

                {#if $showPagination}
                    <Pagination {...$hotelSearchResultsPagination} on:change={handlePageChange} />
                {/if}
            {/if}
        </div>
    </div>

    <div class="update-rankings">
        <Rankings on:save={handleSearch} />
    </div>
</div>

{#if $showBulkUploadModal}
    <BulkUpdateModal on:save={handleSearch} />
{/if}

{#if $showBulkUploadModal || $showLoader}
    <div class="modal-backdrop fade show" />
{/if}

{#if $showLoader}
    <div class="loader">
        <div class="spinner-border" />
    </div>
{/if}

<style>
    :global(body) {
        overflow-y: scroll;
    }
    .hotel-rankings {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
    }

    .return {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        display: flex;
        align-items: center;
        height: 75px;
        border-bottom: 1px solid #ebedef;
        padding: 0 10px;
        z-index: 999;
    }

    .search-form {
        margin-bottom: 30px;
    }

    .search-results {
        margin: 0 0 30px;
    }

    .loader {
        z-index: 99999;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .search-results-footer {
        display: flex;
        justify-content: space-between;
    }

    .search-results-footer .btn {
        margin-right: auto;
    }
</style>
