<script>
    import { hotelSearchResults, selectedHotelsToRank, activeDebtorId } from './hotel-rankings-store';

    function handleSelect(hotel) {
        selectAll = false;

        if ($selectedHotelsToRank[hotel.Id]) {
            $selectedHotelsToRank = delete $selectedHotelsToRank[hotel.Id] && $selectedHotelsToRank;
        } else {
            $selectedHotelsToRank[hotel.Id] = {...hotel};
        }
    }

    let selectAll = false;

    function handleSelectAll() {
        selectAll = !selectAll;

        $hotelSearchResults.forEach((hotel) => {
            if (selectAll) {
                $selectedHotelsToRank[hotel.Id] = {...hotel};
            } else {
                $selectedHotelsToRank = delete $selectedHotelsToRank[hotel.Id] && $selectedHotelsToRank;
            }
        });
    }
</script>

<h4>2. Select hotels to update</h4>

<table class="table table-striped align-middle table-hover">
    <thead>
        <tr>
            <th scope="col" class="hotel-id">ID</th>
            <th scope="col" class="name">Name</th>
            <th scope="col">Country</th>
            <th scope="col" class="city">City</th>
            <th scope="col" class="chain">Chain</th>
            <th scope="col" class="code">Airport Code</th>
            <th scope="col" class="ranking"> CTM Rank</th>
            {#if $activeDebtorId}
                <th scope="col" class="ranking">
                    <span class="badge rounded-pill bg-primary">
                        {$activeDebtorId}
                    </span>
                </th>
            {/if}
            <th scope="col" class="select" on:click={handleSelectAll}>
                <div class="text-end">
                    <input class="form-check-input" type="checkbox" bind:checked={selectAll} />
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        {#each $hotelSearchResults as hotel}
            <tr on:click={() => handleSelect(hotel)}>
                <th scope="row">{hotel.Id}</th>
                <td>{hotel.Name}</td>
                <td>{hotel.Country || '-'}</td>
                <td>{hotel.City || '-'}</td>
                <td>{hotel.Chain || '-'}</td>
                <td>{hotel.AirportCode || '-'}</td>
                <td class="ranking">{hotel.Ranking || '-'}</td>
                {#if $activeDebtorId}
                    <td class="ranking">{hotel.DebtorRanking || '-'} </td>
                {/if}
                <td>
                    <div class="text-end">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkboxNoLabel"
                            checked={$selectedHotelsToRank[hotel.Id]}
                            aria-label="..." />
                    </div></td>
            </tr>
        {:else}
            <tr>
                <td colspan="9">Search for hotels using the form above</td>
            </tr>
        {/each}
    </tbody>
</table>

<style>
    tr,
    td {
        min-height: 48px;
        height: 48px;
    }

    th.hotel-id {
        width: 5%;
    }

    th.name {
        width: 20%;
    }

    th.city {
        width: 12.5%;
    }

    th.code {
        width: 12.5%;

    }

    th.chain {
        width: 12.5%;
    }

    td.ranking,
    th.ranking {
        width: 12.5%;
        text-align: center;
    }

    th.select {
        width: 12.5%;
    }

    tbody {
        position: relative;
    }
</style>
