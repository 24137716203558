<script>
    import { createEventDispatcher } from 'svelte';

    import notification from '@ctm/ui/src/components/Notification/notification';

    import UpdateRank from './UpdateRank.svelte';
    import { selectedHotelsToRank, showLoader, hotelSearchResults, activeDebtorId } from './hotel-rankings-store';

    import { apiCall } from './hotel-rankings-services';

    const dispatch = createEventDispatcher();

    function handleRemoveHotel(Id) {
        $selectedHotelsToRank = delete $selectedHotelsToRank[Id] && $selectedHotelsToRank;
    }

    async function handleSave() {
        $showLoader = true;

        try {
            if ($activeDebtorId) {
                await handleDebtorRankingSave();
            } else {
                await handleRankingSave();
            }

            notification.open({
                title: 'Save complete',
                placement: 'topRight',
            });

            dispatch('save');
        } catch (error) {
            console.warn(error);
        }

        $hotelSearchResults = $hotelSearchResults;
        $showLoader = false;
    }

    async function handleRankingSave() {
        let body = {
            UpdateHotelRankings: [],
        };

        body.UpdateHotelRankings = Object.keys($selectedHotelsToRank).map((key) => {
            return {
                HotelId: Number(key),
                Ranking: $selectedHotelsToRank[key].Ranking,
            };
        });

        try {
            await apiCall('saveHotelRankings', body);
        } catch (error) {
            console.warn(error);
        }
    }
    async function handleDebtorRankingSave() {
        let body = Object.values($selectedHotelsToRank).map((hotel) => {
            return {
                Id: hotel.DebtorHotelId,
                DebtorIdentifier: $activeDebtorId,
                HotelId: hotel.Id,
                Ranking: hotel.DebtorRanking,
                isDeleted: hotel.DebtorRanking ? false : true,
            };
        });

        try {
            let response = await apiCall('saveDebtorHotels', body);

            Object.keys($selectedHotelsToRank).forEach((key) => {
                $selectedHotelsToRank[key].DebtorHotelId = $selectedHotelsToRank[key].DebtorRanking
                    ? response.find((hotel) => hotel.HotelId === $selectedHotelsToRank[key].Id).id
                    : null;
            });
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }
</script>

<h4>3. Update rankings</h4>

<form on:submit|preventDefault={handleSave}>
    <table class="table table-striped align-middle">
        <thead>
            <tr>
                <th scope="col" class="hotel-id">ID</th>
                <th scope="col" class="name">Name</th>
                <th scope="col" class="country">Country</th>
                <th scope="col" class="city">City</th>
                <th scope="col" class="chain">Chain</th>
                <th scope="col" class="airport">Airport Code</th>

                <th scope="col" class="ranking">
                    {#if $activeDebtorId}
                        <span class="badge rounded-pill bg-primary">
                            {$activeDebtorId}
                        </span>
                    {:else}
                        <th scope="col" class="ranking"> CTM Rank</th>
                    {/if}
                </th>

                <th scope="col" class="remove">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            {#each Object.values($selectedHotelsToRank).sort((a, b) => a.Name.localeCompare(b.Name)) as { Id, Name, Country, City, Chain, AirportCode, Ranking, DebtorRanking} (Id)}
                <tr>
                    <th scope="row">{Id}</th>
                    <td>{Name}</td>
                    <td>{Country}</td>
                    <td>{City}</td>
                    <td>{Chain || '-'}</td>
                    <td>{AirportCode || '-'}</td>
                    <td class="ranking">
                        {#if $activeDebtorId}
                            <UpdateRank {Id} Ranking={DebtorRanking} debtorMode />
                        {:else}
                            <UpdateRank {Id} {Ranking} />
                        {/if}
                    </td>

                    <td class="cancel-button"
                        ><button class="btn btn-primary btn-sm" type="button" on:click={handleRemoveHotel(Id)}
                            >Cancel
                        </button></td>
                </tr>
            {:else}
                <tr>
                    <td colspan="8">Select hotels from the search results</td>
                </tr>
            {/each}
        </tbody>
    </table>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
            class="btn btn-primary"
            type="submit"
            disabled={$showLoader || !Object.keys($selectedHotelsToRank).length}>Save rankings</button>
    </div>
</form>

<style>
    tr,
    td {
        min-height: 48px;
        height: 48px;
    }

    th.hotel-id {
        width: 5%;
    }

    th.name {
        width: 20%;
    }

    th.city {
        width: 12.5%;
    }

    th.chain {
        width: 12.5%;
    }

    th.airport {
        width: 12.5%;
    }

    th.ranking,
    td.ranking {
        width: 12.5%;
        text-align: center;
    }

    th.remove {
        width: 12.5%;
    }

    .cancel-button {
        text-align: right;
    }

    :global(.notifyIcon) {
        display: flex !important;
    }

    :root {
        --white: #fff;
        --green_4: #00a859;
    }
</style>
