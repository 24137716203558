<script>
    import { createEventDispatcher } from 'svelte';

    import notification from '@ctm/ui/src/components/Notification/notification';

    import {
        showLoader,
        showBulkUploadModal,
        activeCountry,
        activeCity,
        activeChain,
        activeAirportCode,
        hotelSearchResultsPagination
    } from './hotel-rankings-store';

    import { apiCall } from './hotel-rankings-services';

    const dispatch = createEventDispatcher();

    let newRank = null;

    function handleCloseModal() {
        $showBulkUploadModal = false;
    }

    async function handleSave() {
        $showLoader = true;

        let body = {
            SearchCriteria: {
                Country: $activeCountry.code,
                City: $activeCity,
                ChainCodeName: $activeChain,
                AirportCode: $activeAirportCode
            },
            Ranking: newRank,
        };

        handleCloseModal();

        try {
            await apiCall('bulkUpdate', body);

            notification.open({
                title: 'Bulk update complete',
                placement: 'topRight',
            });

            dispatch('save');

        } catch (error) {
            console.warn(error);
        } finally {
            $showLoader = false;
        }
    }
</script>

<div class="modal show" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Bulk update</h5>
                <button type="button" class="btn-close" on:click={handleCloseModal} />
            </div>
            <div class="modal-body">
                <div class="alert alert-warning" role="alert">
                    This updates <strong>ALL RESULTS</strong> ({$hotelSearchResultsPagination.total} hotels) that are matched to your
                    current search
                </div>

                <label for="new-rank">New rank</label>
                <select id="new-rank" class="form-select" required bind:value={newRank}>
                    {#each Array.from({ length: 15 }, (_, i) => i + 1) as rank}
                        <option value={rank}>{rank}</option>
                    {/each}
                    <option value="clear">Clear ranking</option>
                </select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" on:click={handleCloseModal}>Cancel</button>
                <button type="button" class="btn btn-primary" disabled={!newRank} on:click={handleSave}>Save</button>
            </div>
        </div>
    </div>
</div>

<style>
    .show.modal {
        display: block;
    }

    .form-select {
        max-width: 200px;
        margin: 0 0 20px;
    }

    :global(.notifyIcon) {
        display: flex !important;
    }

    :root {
        --white: #fff;
        --green_4: #00a859;
    }
</style>
