<script>
    import { selectedHotelsToRank } from './hotel-rankings-store';
    export let Ranking;
    export let Id;
    export let debtorMode = false;

    $: key = debtorMode ? 'DebtorRanking' : 'Ranking';

    function handleRankingChange(newRank) {
        Ranking = newRank;
    }

    $: {
        if (!Ranking) {
            Ranking = null;
            $selectedHotelsToRank[Id][key] = null;
        }
        if (Ranking < 16) $selectedHotelsToRank[Id][key] = Ranking;
        if (Ranking > 15) $selectedHotelsToRank[Id][key] = 15;
    }
</script>

<div class="input-group input-group-sm rank">
    <button
        class="btn btn-primary"
        type="button"
        disabled={!Ranking}
        on:click={() => handleRankingChange(Ranking - 1)}>-</button>
    <input
        type="number"
        class="form-control"
        min="0"
        max="15"
        on:change={(e) => handleRankingChange(e.target.value)}
        bind:value={Ranking} />
    <button
        class="btn btn-primary"
        type="button"
        disabled={Ranking === 15}
        on:click={() => handleRankingChange(Ranking ? Ranking + 1 : 1)}>+</button>
</div>

<style>
    .rank {
        max-width: 90px;
    }

    .rank input {
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .input-group {
        margin: auto;
    }
</style>
