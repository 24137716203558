<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    import CountrySearch from './CountrySearch.svelte';
    import DebtorSearch from './DebtorSearch.svelte';

    import { showLoader, selectedCity, selectedChain, selectedAirportCode, onlyShowDebtorHotels, selectedChainCode } from './hotel-rankings-store';

    function handleSearch() {
        dispatch('search');
    }
</script>

<h4>1. Search for hotels</h4>

<form class="search-form" on:submit|preventDefault={handleSearch}>
    <div class="content" class:waiting={$showLoader}>
        <div class="filters">
            <div class="row mb-3">
                <label for="Country" class="col-sm-2 col-form-label">Country</label>
                <div class="col-sm-10">
                    <CountrySearch />
                </div>
            </div>

            <div class="row mb-3">
                <label for="city" class="col-sm-2 col-form-label">City</label>
                <div class="col-sm-10">
                    <input id="city" type="text" class="form-control" bind:value={$selectedCity} />
                </div>
            </div>

            <div class="row mb-3">
                <label for="airport" class="col-sm-2 col-form-label">Airport Code</label>
                <div class="col-sm-10">
                    <input id="airport" type="text" class="form-control" bind:value={$selectedAirportCode} />
                </div>
            </div>

            <div class="row mb-3">
                <label for="chain" class="col-sm-2 col-form-label">Chain</label>
                <div class="col-sm-10">
                    <input id="chain" type="text" class="form-control" bind:value={$selectedChain} />
                </div>
            </div>

            <div class="row mb-3">
                <label for="chain-code" class="col-sm-2 col-form-label">Chain Code</label>
                <div class="col-sm-10">
                    <input id="chain-code" type="text" class="form-control" bind:value={$selectedChainCode} />
                </div>
            </div>
        </div>
    </div>

    <div class="content" class:waiting={$showLoader}>
        <div class="filters">
            <div class="row mb-3">
                <label for="chain" class="col-sm-2 col-form-label">Debtor</label>
                <div class="col-sm-10">
                    <DebtorSearch />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-sm-10 offset-sm-2">
                    <div class="form-check">
                        <input
                            bind:checked={$onlyShowDebtorHotels}
                            class="form-check-input"
                            type="checkbox"
                            id="gridCheck" />
                        <label class="form-check-label" for="gridCheck"> Only show hotels with a debtor ranking </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="search-controls">
        <button class="btn btn-primary" type="submit">Search</button>
    </div>
</form>

<style>
    .search-form {
        display: flex;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 20px 0;
        flex-wrap: wrap;
    }

    .search-form .content {
        padding: 0 20px;
        flex-grow: 1;
    }

    .search-form .content:first-child {
        border-right: 1px solid #ccc;
    }

    .filters {
        display: flex;
        flex-direction: column;
    }

    .content.waiting {
        opacity: 0.5;
        pointer-events: none;
    }

    .search-controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 0;
    }

    .search-controls button {
        margin-left: auto;
    }
</style>
