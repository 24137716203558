import { writable } from 'svelte/store';

export const hotelSearchResults = writable([]);
export const selectedHotelsToRank = writable({});
export const hotelSearchResultsPagination = writable({ current: 1 });
export const showLoader = writable(false);
export const selectedDebtorId = writable();
export const activeDebtorId = writable(null);
export const onlyShowDebtorHotels = writable(false);
export const showPagination = writable(false);
export const selectedCountry = writable({ name: 'Australia', code: 'AU' });
export const activeCountry = writable(null);
export const selectedCity = writable(null);
export const activeCity = writable(null);
export const selectedChain = writable(null);
export const activeChain = writable(null);
export const selectedChainCode = writable(null);
export const activeChainCode = writable(null);
export const selectedAirportCode = writable(null);
export const activeAirportCode = writable(null);
export const showBulkUploadModal = writable(false);