<script>
    import Select from 'svelte-select';
    import { onDestroy } from 'svelte';

    import { selectedDebtorId } from './hotel-rankings-store';
    import { searchDebtors } from './hotel-rankings-services';

    async function handleSearch(filterText) {
        let res = await searchDebtors(filterText);
        return res.value.map((debtor) => debtor.Name);
    }

    async function handleSelect(e) {
        $selectedDebtorId = e.detail.value;
    }

    function handleClear() {
        $selectedDebtorId = null;
    }

    onDestroy(() => {
        handleClear();
    });
</script>

<div class="debtor-search">
    <Select
        value={$selectedDebtorId}
        on:clear={handleClear}
        on:select={handleSelect}
        placeholder="Search for a debtor"
        loadOptions={handleSearch} />
</div>

<style>
    .debtor-search {
        flex-grow: 1;
        --height: 38px;
        --borderRadius: 0.25rem;
        --clearSelectTop: 6px;
    }
</style>
